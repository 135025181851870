<template>
  <section class="Add_Post_Components">
    <iq-card v-if="isShowAddPostModal" id="post-modal-data">
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t("Posts.15b@create_post") }}</h4>
      </template>
      <template v-slot:body>
        <div class="d-flex align-items-center" @click="
            $bvModal.show('modal1');
            isPostClose = false;
            scrollEvent();
          ">
          <div class="user-img mr-3">
            <!-- <img
              v-if="userInfo.avatar"
              style="object-fit: cover"
              class="avatar-60 rounded-circle"
              :src="userInfo.avatar"
            /> -->
            <el-avatar :size="58" :src="userInfo.avatar" @error="true">
              <img src="@/assets/images/public/user.png" />
            </el-avatar>
          </div>
          <p class="m-0">{{ $t("Posts.460@write_something_here") }}</p>
        </div>
        <hr />
        <!-- f非弹框的媒体列表 -->
        <div class="no-popup">
          <ul class="p-0 m-0 media_box">
            <li class="li_pic" v-for="(item, index) in addPostObj.media" :key="index">
              <img v-if="item.type == 'photo'" :src="item.url" alt="" />
              <video v-else :src="item.url" controls autoplay></video>
              <img class="close_img" src="../../../assets/images/add_post_close.png" @click="unshiftMedia(index)"
                alt="" />
              <!-- <i class="las la-times-circle" ></i> -->
            </li>
          </ul>

          <!-- friend -->
          <ul class="modal_friend_list p-0 m-0">
            <li v-for="(item, index) in friendsInfoList" class="friend_box" :key="index">
              <span class="text-primary">@{{ item.nick_name }}</span>
              <i class="el-icon-close" @click="delFriend(index)"></i>
            </li>
          </ul>

          <!-- location -->
          <div class="d-flex" v-if="addPostObj.location">
            <section class="location_box m-0">
              <i class="ri-map-pin-line"></i>
              <span>{{ addPostObj.location }}</span>
              <i class="el-icon-close" @click="addPostObj.location = ''"></i>
            </section>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <ul class="post-opt-block d-flex align-items-center list-inline m-0 p-0">
            <li class="iq-bg-primary self-pointer mr-2">
              <div class="iq-bg-primary rounded p-2">
                <el-tooltip class="item" effect="light" :content="$t('Posts.83d@photo_video')" placement="top-start"
                  :disabled="flagOne">
                  <el-upload class="avatar-uploader" action="no" :http-request="
                      (params) => {
                        imgUpload(params, 1);
                      }
                    " :show-file-list="false" v-loading="loadingImg1" multiple>
                    <img src="@/assets/images/small/07.png" class="img-fluid" />
                    <span id="my_test_one" class="my_test">{{
                      $t("Posts.83d@photo_video")
                      }}</span>
                  </el-upload>
                </el-tooltip>
              </div>
            </li>
            <li class="iq-bg-primary self-pointer mr-2">
              <b-dropdown style="width: 100%">
                <template #button-content>
                  <el-tooltip class="item" effect="light" :content="$t('Posts.80b@tag_friend')" placement="top-start"
                    :disabled="flagTwo">
                    <div class="iq-bg-primary rounded p-2 width_120">
                      <img src="@/assets/images/small/08.png" class="img-fluid" />
                      <span id="my_test_two" class="my_test">{{
                        $t("Posts.80b@tag_friend")
                        }}</span>
                    </div>
                  </el-tooltip>
                </template>
                <div class="pl-2 pr-2">
                  <b-input :placeholder="$t('Posts.7ec@search_or_select_fri')" v-model="searchFriend"></b-input>
                </div>
                <b-dropdown-item @click="chooseOneFriend(item)" v-for="(item, index) in Newitems" :key="index">
                  {{ item.nick_name }}</b-dropdown-item>
              </b-dropdown>
            </li>
            <li class="iq-bg-primary self-pointer rounded mr-2">
              <b-dropdown style="width: 100%">
                <template #button-content>
                  <el-tooltip class="item" effect="light" :content="$t('Posts.5f6@check_in')" placement="top-start"
                    :disabled="flagThree">
                    <div @click="googleNearbyFunc" class="iq-bg-primary p-2 text-align-center width_120">
                      <img src="@/assets/images/small/10.png" class="img-fluid" />
                      <span id="my_test_three" class="my_test">{{
                        $t("Posts.5f6@check_in")
                        }}</span>
                    </div>
                  </el-tooltip>
                </template>

                <div v-if="addressLoading == 'error'" @click="googleNearbyFunc"
                  class="d-flex justify-content-center align-content-xl-start">
                  <i class="ri-refresh-line"></i>{{ $t("Posts.3f9@reload") }}
                </div>
                <div class="m-3 p-3" v-if="addressLoading == 'loading'">
                  <div v-loading="true"></div>
                </div>

                <div v-infinite-scroll="googleNearbyFunc" infinite-scroll-disabled="addressDis"
                  infinite-scroll-delay="500" v-if="addressLoading == 'success'">
                  <b-dropdown-item @click="chooseAddress(item)" v-for="(item, index) in AddressList" :key="index">
                    {{ item.name }}</b-dropdown-item>
                </div>
              </b-dropdown>
            </li>
          </ul>

          <div class="d-flex align-items-center justify-content-end flex-shrink-0">
            <el-dropdown @command="handlePermissionCommand" class="mr-2" trigger="click">
              <span class="el-dropdown-link">
                {{ permission | permissionFilter
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="1">
                  {{ $t("Posts.c0c@public") }}</el-dropdown-item>
                <el-dropdown-item :command="0">{{
                  $t("Posts.9df@friends")
                  }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <button :disabled="!isPostBtnDis" class="btn btn-primary" @click="addNewPost">
              {{ $t("Posts.a63@post") }}
            </button>
          </div>
        </div>
      </template>
    </iq-card>

    <!-- created Post Modal -->
    <b-modal id="modal1" centered :title="$t('Posts.15b@create_post')" hide-footer @hidden="hideModalFunc">
      <hr class="m-0" />
      <!--  -->
      <div class="d-flex content_post">
        <div class="user-img">
          <img v-if="userInfo.avatar" style="object-fit: cover" class="avatar-60 rounded-circle"
            :src="userInfo.avatar" />
        </div>
        <!-- <el-input class="textareaPost" type="textarea" v-model="addPostObj.content" maxlength="1000"
          placeholder="Write something here..." show-word-limit>
        </el-input> -->
        <section style="flex: 1">
          <section class="post_remark_content">
            <div class="detail_content" id="detail_content">
              <p v-html="remarkContent" id="p-content"></p>
            </div>
            <div class="showWarning" v-show="textTotal>1000">
              <span style="color: #f56c6c"> Cannot exceed 1000 words </span>
            </div>
            <div class="showLave">{{ textTotal }} / 1000</div>
            <div class="postTextarea">
              <textarea 
                id="textarea_content" 
                @blur="(val) => limitInput(val, addPostObj.content)"
                @keyup.32="(e) => limitInput(e, addPostObj.content)"
                v-model="addPostObj.content"
                :placeholder="$t('Posts.460@write_something_here')" style="
                  width: calc(100% - 15px);
                  height: 100%;
                  border: none;
                  resize: none;
                "></textarea>
              <!-- <div class="showLave">{{ addPostObj.content.length }} / 1000</div> -->
            </div>
          </section>

          <!-- location -->
          <div class="mb-3 d-flex" v-if="addPostObj.location">
            <section class="location_box">
              <i class="ri-map-pin-line"></i>
              <span>{{ addPostObj.location }}</span>
              <i class="el-icon-close" @click="addPostObj.location = ''"></i>
            </section>
          </div>

          <!-- friend -->
          <ul class="modal_friend_list p-0">
            <li v-for="(item, index) in friendsInfoList" class="friend_box" :key="index">
              <!-- <span class="del" style="cursor: pointer" @click="delFriend(index)">×</span> -->
              <!-- <img class="close_img del" src="../../../assets/images/add_post_close.png" @click="delFriend(index)" alt="" /> -->
              <span class="text-primary">@{{ item.nick_name }}</span>
              <i class="el-icon-close" @click="delFriend(index)"></i>
            </li>
          </ul>

          <!-- 拖拽上传图片 -->
          <div class="drag_container" v-loading="loadingImg3">
            <el-upload class="upload-demo" drag action="no" :http-request="
                (params) => {
                  imgUpload(params, 3);
                }
              " :show-file-list="false" multiple>
              <div class="all_image d-flex flex-wrap" v-if="addPostObj.media.length">
                <div class="common_item" :class="imgClass" v-for="(v, i) in addPostObj.media" :key="i">
                  <img v-if="v.type == 'photo'" :src="v.url" alt="" class="main_img" />
                  <video v-else :src="v.url" controls autoplay></video>
                  <img class="close_img" src="@/assets/images/add_post_close.png" @click.stop="unshiftMedia(i)" />
                </div>
              </div>
              <div class="
                  drag_content
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                " v-else>
                <img src="@/assets/images/icon/drag_icon.png" alt="" />
                <div class="el-upload__text mt-3">
                  Add Pictures / Video Drag & Drop
                </div>
              </div>
            </el-upload>
          </div>
        </section>
      </div>

      <div style="padding: 0 30px">
        <hr />
      </div>

      <!-- media -->
      <!-- <ul class="p-0 m-0 media_box">
        <li class="li_pic" v-for="(item, index) in addPostObj.media" :key="index">
          <img v-if="item.type == 'photo'" :src="item.url" alt="" />
          <video v-else :src="item.url" controls autoplay></video>
          <img class="close_img" src="../../../assets/images/add_post_close.png" @click="unshiftMedia(index)" alt="" />
          <i class="las la-times-circle" ></i>
        </li>
      </ul> -->

      <!-- share -->
      <div v-if="shareLink" class="share_link mb-2" style="margin: 0 30px">
        <div style="width: 80px" class="img_box" v-if="
            sharePostInfomation.media && sharePostInfomation.media.length > 0
          ">
          <img style="object-fit: cover" v-if="sharePostInfomation.media[0].type == 'photo'"
            :src="sharePostInfomation.media[0].url" alt="" />
          <video v-else :src="sharePostInfomation.media[0].url"></video>
        </div>
        <div style="width: 80px" class="img_box" v-else>
          <img style="object-fit: cover" :src="sharePostInfomation.image" alt="" />
        </div>
        <div class="share_content">
          <span v-if="sharePostInfomation.nick_name">@{{ sharePostInfomation.nick_name }}</span>
          <span v-if="sharePostInfomation.title">{{
            sharePostInfomation.title
            }}</span>
          <p class="m-0" v-html="postContentFilter(sharePostInfomation.content)"></p>
        </div>
      </div>

      <!-- tag -->
      <section class="
          d-flex
          align-items-center
          list-inline
          m-0
          justify-content-between
          option_container
        ">
        <div class="d-flex diaAddPost align-items-center">
          <div>
            <div class="iq-bg-primary rounded p-2 pointer mr-2">
              <el-upload class="avatar-uploader" action="no" :http-request="
                  (params) => {
                    imgUpload(params, 2);
                  }
                " :show-file-list="false" v-loading="loadingImg" multiple>
                <img src="@/assets/images/small/07.png" class="img-fluid" />
                <span class="my_test">{{ $t("Posts.83d@photo_video") }}</span>
              </el-upload>
            </div>
          </div>
          <div class="mr-2">
            <b-dropdown style="width: 100%">
              <template #button-content>
                <div class="iq-bg-primary rounded text-left p-2 pointer">
                  <img src="@/assets/images/small/08.png" class="img-fluid" />
                  <span class="my_test">{{ $t("Posts.80b@tag_friend") }}</span>
                </div>
              </template>
              <div class="pl-2 pr-2">
                <b-input :placeholder="$t('Posts.7ec@search_or_select_fri')" v-model="searchFriend"></b-input>
              </div>
              <b-dropdown-item @click="chooseOneFriend(item)" v-for="(item, index) in Newitems" :key="index">
                {{ item.nick_name }}</b-dropdown-item>
            </b-dropdown>
          </div>
          <div>
            <b-dropdown style="width: 100%">
              <template #button-content>
                <div @click="googleNearbyFunc" class="iq-bg-primary rounded text-left p-2 pointer mr-3">
                  <img src="@/assets/images/small/10.png" class="img-fluid" />
                  <span class="my_test">{{ $t("Posts.5f6@check_in") }}</span>
                </div>
              </template>

              <div v-if="addressLoading == 'error'" @click="googleNearbyFunc"
                class="d-flex justify-content-center align-content-xl-start">
                <i class="ri-refresh-line"></i>
                {{ $t("Posts.3f9@reload") }}
              </div>
              <div class="m-3 p-3" v-if="addressLoading == 'loading'">
                <div v-loading="true"></div>
              </div>

              <div v-infinite-scroll="googleNearbyFunc" infinite-scroll-disabled="addressDis"
                infinite-scroll-delay="500" v-if="addressLoading == 'success'">
                <b-dropdown-item @click="chooseAddress(item)" v-for="(item, index) in AddressList" :key="index">
                  {{ item.name }}</b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
        </div>
        <!-- permission -->
        <div class="other-option" v-if="noShowPermission">
          <!-- <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <h6>{{ permission | permissionFilter }}</h6>
            </div>
            <div class="iq-card-post-toolbar">
              <b-dropdown id="dropdownMenuButton40" variant="none" menu-class="p-0" v-model="permission">
                <template v-slot:button-content>
                  <button href="#" class="dropdown-toggle btn btn-primary">
                    {{ permission | permissionFilter }}
                  </button>
                </template>
                <b-dropdown-item @click="permission = 1" class="dropdown-item p-0">
                  <div class="d-flex align-items-center">
                    <i class="ri-save-line" style="font-size: 16px"></i>
                    <div class="data ml-2">
                      <h6 class="pt-2 pb-2">{{ $t("Posts.c0c@public") }}</h6>
                      <p class="mb-0">Anyone on or off Facebook</p>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item @click="permission = 0" class="dropdown-item p-0">
                  <div class="d-flex align-items-center">
                    <i class="ri-close-circle-line"></i>
                    <div class="data ml-2">
                      <h6 class="pt-2 pb-2">{{ $t("Posts.9df@friends") }}</h6>
                      <p class="mb-0">Your friend on facebook</p>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div> -->

          <el-dropdown @command="handlePermissionCommand" trigger="click">
            <span class="el-dropdown-link">
              {{ permission | permissionFilter
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="1">{{
                $t("Posts.c0c@public")
                }}</el-dropdown-item>
              <el-dropdown-item :command="0">{{
                $t("Posts.9df@friends")
                }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </section>

      <button @click="addNewPost" class="btn btn-primary d-block w-100 add_new_post">
        {{ $t("Posts.a63@post") }}
      </button>
    </b-modal>
  </section>
</template>

<script>
  import bus from "@/Utils/Bus";
  import { mapGetters } from "vuex";
  import { throttle } from "@/Utils/globalFn";

  import { InfiniteScroll } from "element-ui";
  export default {
    name: "AddSocialPost",
    inject: ["reload"],
    props: [
      "openAddPost",
      "friendList",
      "noShowPermission",
      "isShowAddPostModal",
    ],
    directives: {
      "infinite-scroll": InfiniteScroll,
    },
    data() {
      return {
        avatar: "",

        // 朋友可见||全部可见
        permission: 1,

        // 地址列表loading
        addressLoading: false,
        addressDis: false,

        // 发帖项
        addPostObj: {
          content: "",
          media: [],
          friends: [],
          location: "",
          longitude: "",
          latitude: "",
          share_type: null,
          share_id: null,
        },

        // 页面上遍历@friends
        friendsInfoList: [],

        // 页面上地址列表
        AddressList: [],
        nextAddressToken: "",

        // 分享
        shareLink: false,
        sharePostInfomation: {},
        shareId: 0,

        searchFriend: "",

        // 判断是否是误触关闭发帖弹框
        isPostClose: false,

        flagOne: true,
        flagTwo: true,
        flagThree: true,

        loadingImg: false,
        loadingImg1: false,
        loadingImg3: false,
        // remarkContent: ''

        textTotal: 0,
      };
    },

    filters: {
      permissionFilter(val) {
        return val == 1 ? "Public" : "Friends";
      },
    },

    computed: {
      ...mapGetters({
        userInfo: "Public/getUserInfo",
      }),
      isPostBtnDis() {
        // console.log('dis', this.addPostObj.media.length || this.addPostObj.friends.length || this.addPostObj.location);
        return (
          this.addPostObj.media.length ||
          this.addPostObj.friends.length ||
          this.addPostObj.location
        );
      },
      Newitems() {
        let friendList = [];
        this.friendList.map((item) => {
          if (!item.nick_name) return;
          if (
            item.nick_name.toUpperCase().includes(this.searchFriend.toUpperCase())
          ) {
            friendList.push(item);
          }
        });
        return friendList;
      },

      // 判斷表单有没有填写

      // 判断样式
      imgClass() {
        if (this.addPostObj.media.length == 1) return "one_img";
        if (this.addPostObj.media.length == 4) return "four_img";
        return "image_item";
      },

      remarkContent() {
        // console.log(this.addPostObj.content);
        let val = this.addPostObj.content;
        let reg = /(^|\s+)#([^!@#$%^&*()=+./,:'"?<>(^|\s+)]+)/g;
        val = val.replace(reg, `<a>$1#$2</a>`)
        // val = val.replace(reg, `<a>$1#$2</a>`)
        val = val.replace(/(\r\n|\n|\r)/gm, "<br />").replace(/\s{2}/g, "&nbsp;&nbsp;");
        // console.log(val);
        return val;
      },
    },

    created() {
      this._fnBusOn();
      // console.log("this._fnBusOn()");
    },

    watch: {
      $route: {
        immediate: true, // 监听到路由的变化立即执行
        handler(to, from) {
          this.$bus.$off("editPost");
        },
      },
    },
    mounted() {
      this.getUserInfo();
      // 分享
      bus.$on("share", (e) => {
        this.$bvModal.show("modal1");
        this.shareLink = true;
        this.getShareInfo(e.info_url || e.user_post_id);
        this.shareId = e.id || e.post_id;
        this.shareData = e;
      });

      // ...............
      this._fnTooltip();
      this.$bus.$on("showTooltip", () => this._fnTooltip());
    },
    // 解决$on接收多次的问题
    beforeDestroy() {
      bus.$off("share");
      bus.$off("showTooltip");
      // console.log("bus.$off('editPost'----pro)");
      // this.$bus.$off('editPost')
    },

    methods: {
      async limitInput(val, keyword) {
      // console.log(val, keyword);
      let res = await this.$http.getContentNumber({ keyword });
      if (res.status == 200) {
        this.textTotal = res.data;
      }
    },
      // 文本域滚动条事件
      scrollEvent() {
        setTimeout(() => {
          document.getElementById("textarea_content").addEventListener("scroll", handleScroll);
        }, 0);
        function handleScroll() {
          //获取dom滚动距离
          let textareaTop = document.getElementById("textarea_content");
          let contentTop = document.getElementById('detail_content');
          let p_content = document.getElementById('p-content');
          // console.log("textareaTop.scrollHeight", textareaTop.scrollHeight);
          // console.log("contentTop.scrollHeight", contentTop.scrollHeight);
          // console.log("p_content.scrollHeight", p_content.scrollHeight);
          p_content.style.height = textareaTop.scrollHeight + 'px'
          contentTop.scrollTop = textareaTop.scrollTop;
        }
      },
      // ..........bus.........
      _fnBusOn() {
        this.$bus.$on("editPost", (id) => {
          this.$bvModal.show("modal1");
          // this.addPostObj = JSON.parse(JSON.stringify(post))
          // console.log("this.addPostObj", this.addPostObj);
          this.$http.updatePostInfo({ id }).then((res) => {
            if (res.status == 200) {
              this.addPostObj = res.data.post;
              this.$set(this.addPostObj, "media", []);
              this.addPostObj.media = res.data.media;
              this.friendsInfoList = res.data.friend;
              this.addPostObj.friends = res.data.friend.map((v) => v.user_id);
              // console.log("this.addPostObj", this.addPostObj);
              if (res.data.share) {
                this.shareLink = true;
                this.sharePostInfomation = res.data.share;
                this.shareId = res.data.share.id;
              }
            }
          });
        });
      },
      // 删除好友
      delFriend(index) {
        this.friendsInfoList.splice(index, 1);
        this.addPostObj.friends.splice(index, 1);
      },
      // ......post是否需要鼠标悬浮显示......
      _fnTooltip() {
        this.$nextTick(() => {
          let boxOne = document.getElementById("my_test_one") || {};
          let boxTwo = document.getElementById("my_test_two") || {};
          let boxThree = document.getElementById("my_test_three") || {};
          boxOne.scrollWidth > boxOne.offsetWidth
            ? (this.flagOne = false)
            : (this.flagOne = true);
          boxTwo.scrollWidth > boxTwo.offsetWidth
            ? (this.flagTwo = false)
            : (this.flagTwo = true);
          boxThree.scrollWidth > boxThree.offsetWidth
            ? (this.flagThree = false)
            : (this.flagThree = true);
          // console.log("boxOne.scrollWidth", boxOne.scrollWidth);
          // console.log("boxOne.offsetWidth", boxOne.offsetWidth);
        });
      },

      // qingkong表单
      clearForm() {
        this.addPostObj.id = "";
        this.addPostObj.content = "";
        this.addPostObj.friends = [];
        this.addPostObj.media = [];
        this.addPostObj.share_type = null;
        this.friendsInfoList = [];
        // this.AddressList = [];
        this.addPostObj.location = "";
        this.searchFriend = "";

        this.shareLink = false;
      },

      // 页面上帖子权限得选择
      handlePermissionCommand(val) {
        this.permission = val;
      },

      // 监听modal关闭
      hideModalFunc(bvModalEvt, modalId) {
        this.limitInput();
        // console.log('bvModalEvt', bvModalEvt);
        if (this.isPostClose) {
          // 是正常的发帖关闭
          this.clearForm();
        } else {
          if (
            !this.addPostObj.content &&
            !this.addPostObj.friends.length &&
            !this.addPostObj.media.length &&
            !this.addPostObj.share_type &&
            !this.friendsInfoList.length &&
            // this.AddressList = [];
            !this.addPostObj.location &&
            !this.searchFriend
          ) {
            return this.clearForm();
          }
          this.$confirm(this.$t("Posts.488@close_the_pop_up_box"), "Tips", {
            distinguishCancelAndClose: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          })
            .then(() => {
              this.clearForm();
            })
            .catch(() => {
              this.$bvModal.show("modal1");
            });
        }
      },
      // 帖子内容过滤
      postContentFilter(val) {
        if (!val) return;
        let exp =
          /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        val = val.replace(
          exp,
          "<a href='$1' target='_blank'><i class='ri-link-m'></i>Link</a>"
        );
        let url = window.location.href.split('#')[0]
        let reg = /(^|\s+)#([^!@#$%^&*()=+./,:'"?<>(^|\s+)]+)/g;
        val = val.replace(
          reg,
          `<a href='${url}#/search-result?keyword=$2'>$1#$2</a>`
        );
        val = val.replace(/(\r\n|\n|\r)/gm, "<br />");
        return val;
      },
      // 上传
      async imgUpload(params, type) {
        if (this.addPostObj.media.length >= 9)
          return this.$message.warning("No more than 9");
        if (type == 1) {
          this.loadingImg1 = true;
        } else if (type == 2) {
          this.loadingImg = true;
        } else {
          this.loadingImg3 = true;
        }
        const isJPG = params.file.type.split("/")[0] === "image";
        const isLt5M = params.file.size / 1024 / 1024 < 5;
        // console.log("params.file.size / 1024 / 1024", params.file.size / 1024 / 1024);
        this.isLt5MYS = true;
        var formData = new FormData();
        if (isJPG && !isLt5M) {
          await this._reader(params.file);
          let newFile = new window.File([this.newFile], params.file.name, {
            type: params.file.type,
          });
          formData.append("file", newFile);
        } else {
          formData.append("file", params.file);
        }
        if (!this.isLt5MYS) return;
        this.$http.uploadImage(formData).then((res) => {
          this.loadingImg = false;
          this.loadingImg1 = false;
          this.loadingImg3 = false;
          if (res.status == 200 && this.addPostObj.media.length < 9) {
            this.addPostObj.media.push({
              type: params.file.type.split("/")[0] == "image" ? "photo" : "video",
              url: res.data[0],
            });
          }
          // console.log(this.addPostObj.media);
          // console.log(this.addPostObj);
        });
      },

      // async beforeAvatarUpload(file) {
      //   const isJPG = file.type.split("/")[0] === "image";
      //   const isLt5M = file.size / 1024 / 1024 < 5;
      //   if (isJPG && !isLt5M) {
      //     await this._reader(file);
      //     return this.isLt5MYS;
      //   } else {
      //     return isLt5M;
      //   }
      // },

      _reader(file) {
        return new Promise(async (resolve, reject) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async () => {
            await this.canvasDataURL(
              {
                base64: reader.result,
                name: file.name,
              },
              file.type
            );
            // console.log("this.newFile.size / 1024 / 1024", this.newFile.size / 1024 / 1024);
            this.isLt5MYS = this.newFile.size / 1024 / 1024 < 5;
            if (!this.isLt5MYS) {
              this.loadingImg = false;
              this.loadingImg1 = false;
              this.loadingImg3 = false;
              this.$message.warning(this.$t("Posts.b0c@upload_pictures_can"));
            }
            resolve();
          };
        });
      },
      // 利用canvas压缩图片，根据画布大小和图像质量压缩
      canvasDataURL(obj, fileType) {
        return new Promise(async (resolve, reject) => {
          let that = this;
          var img = new Image();
          img.src = obj.base64;
          img.onload = function () {
            // 获取原始尺寸
            var canvasWidth = this.width;
            var canvasHeight = this.height;
            // 生成canvas
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            // canvas对图片的尺寸
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            // 清除画布
            ctx.clearRect(0, 0, canvasWidth, canvasHeight);
            // 图片压缩
            ctx.drawImage(this, 0, 0, canvasWidth, canvasHeight);
            // 图像质量,值越小，所绘制出的图像越模糊
            var quality = 0.8;
            var base64String = canvas.toDataURL(fileType, quality);
            // 通过base64获取二进制文件
            that.newFile = that.getBlobByBase64(base64String, fileType);
            resolve();
          };
        });
      },
      // Base64转文件流
      getBlobByBase64(base64String, fileType) {
        var arr = base64String.split(",");
        // mine = arr[0].match(/:(.*?);/)[1],
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], {
          type: fileType,
        });
      },

      // 删除已上传的图片
      unshiftMedia(index) {
        this.addPostObj.media.splice(index, 1);
      },

      // friendsInfoList页面上的@friends
      chooseOneFriend(user) {
        if (this.addPostObj.friends.includes(user.user_id)) return;
        this.addPostObj.friends.push(user.user_id);
        this.friendsInfoList.push(user);
      },
      // addressList
      googleNearbyFunc() {
        this.addressLoading = "loading";
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.$http
                .googleNearby({
                  longitude: position.coords.longitude,
                  latitude: position.coords.latitude,
                  pagetoken: this.nextAddressToken ? this.nextAddressToken : "",
                })
                .then((res) => {
                  if (res.status == 200) {
                    this.AddressList = [...this.AddressList, ...res.data.name];
                    if (!res.data.next_page_token) {
                      this.addressDis = true;
                    }
                    this.nextAddressToken = res.data.next_page_token;
                    this.addressLoading = "success";
                  } else {
                    this.addressLoading = "error";
                  }
                });
            },
            (error) => {
              switch (error.code) {
                case error.PERMISSION_DENIED:
                  this.$message.warning(
                    this.$t("Posts.8c9@you_reject_the_reque")
                  );
                  break;
                case error.POSITION_UNAVAILABLE:
                  this.$message.warning(
                    this.$t("Posts.64b@location_information")
                  );
                  break;
                case error.TIMEOUT:
                  this.$message.warning(
                    this.$t("Posts.745@request_for_your_loc")
                  );
                  break;
                case error.UNKNOWN_ERROR:
                  this.$message.warning(this.$t("Posts.39c@unknown_mistake"));
                  break;
              }
            }
          );
        } else {
          this.$message.warning(this.$t("Posts.731@your_browser_does_no"));
        }
      },

      chooseAddress(address) {
        this.addPostObj.location = address.name;
        this.addPostObj.longitude = address.location.longitude;
        this.addPostObj.latitude = address.location.latitude;
      },

      // // 获取个人信息
      getUserInfo() {
        this.userInfoList = this.$store.state.Public.userInfo;
        this.avatar = this.$store.state.Public.userInfo.avatar;
        // this.$http.getUserInfo().then((res) => {
        //   if (res.status == 200) {
        //     this.userInfoList = res.data;
        //     this.avatar = res.data.user_info.avatar;
        //   }
        // });
      },

      // 新增帖子
      addNewPost() {
        this.$http.getContentNumber({ keyword: this.addPostObj.content }).then(res=>{
          if (res.status == 200) {
            this.textTotal = res.data;
            if (this.textTotal > 1000) {
              this.$message.warning("Cannot exceed 1000 words!")
            } else if (this.textTotal < 1) {
              this.$message.warning("Content is empty!")
            }else{
              this.isPostClose = true;
              if (this.shareLink) {
                // 分享--share_type为分享类型
                this.addPostObj.share_type = 1;
                this.addPostObj.share_id = this.shareId;
              } else {
                this.addPostObj.share_id = "";
              }
              this.addPostObj.content = this.addPostObj.content
                .replace(/</g, "&lt;")
                .replace(/>/g, "&gt;")
                .replace(/"/g, "&quot;")
                .replace(/‘/g, "&#039;");
              // .replace(/(\r\n|\n|\r)/gm, "<br />");

              // console.log(myString);
              // console.log("this.addPostObj2222", this.addPostObj);
              this.$emit("addPost", this.addPostObj, this.permission, this.shareData);
              this.clearForm();
              this.$bvModal.hide("modal1");
            }
          }
        });
      },

      // 分享
      getShareInfo(id) {
        this.$http
          .getPostInfo({
            user_post_id: id,
          })
          .then((res) => {
            this.sharePostInfomation = res.data;
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  // 地址弹框
  ::v-deep .dropdown-menu {
    width: 350px;
    height: 300px;
    overflow: auto;
  }

  .my_test {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .width_120 {
    /* width: 120px; */
    display: flex !important;
  }

  ::v-deep #modal1 {
    textarea {
      // margin-left: 20px;
      box-sizing: border-box;
      color: transparent;
      background-color: transparent;
      caret-color: #000;
      padding: 0;

      &:focus {
        outline: none;
      }
    }

    textarea::-webkit-input-placeholder {
      line-height: 56px;
    }

    textarea::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      line-height: 56px;
    }

    textarea:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      line-height: 56px;
    }

    textarea:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      line-height: 56px;
    }

    .modal-content,
    .modal-header {
      width: 660px !important;
      border-bottom: 0;
    }
  @media (min-width: 600px) {
    .modal-content,
    .modal-header {
      width: 660px !important;
      border-bottom: 0;
    }
    .el-upload-dragger {
      width: 500px;
      border: 0;
      height: auto;
      overflow: visible;
    }
    .one_img {
      text-align: left;

      .main_img,
      video {
        width: auto;
        height: auto;
        max-width: 500px !important;
        max-height: 500px;
      }
    }
    .four_img {
      width: 160px;
      height: 160px;
      margin-right: 10px;
    }
    .image_item {
      width: 160px;
      height: 160px;
      margin-right: 10px;
    }
  }
  @media (max-width: 600px) {
    .modal-content,
    .modal-header {
      width: 100% !important;
      border-bottom: 0;
    }
    .el-upload-dragger {
      width: 100%;
      border: 0;
      height: auto;
      overflow: visible;
    }
    .content_post {
      padding: 10px 15px 0;
    }
    .user-img {
      width: 40px;
      height: 40px;
      .avatar-60{
        width: 40px;
        height: 40px;
      }
    }
    .one_img {
      text-align: left;

      .main_img,
      video {
        width: auto;
        height: auto;
        max-width: 100% !important;
        max-height: 500px;
      }
    }
    .four_img {
      width: 90px;
      height: 90px;
      margin-right: 10px;
    }
    .image_item {
        width: 100px;
        height: 100px;
        margin-right: 10px;
      }
  }
    .modal-body {
      padding: 0;
      box-sizing: border-box;
    }

    .content_post {
      padding: 15px 30px 0;
    }

    hr {
      border-color: #f1f1f1;
    }

    .option_container {
      padding: 0 30px;
    }

    .el-dropdown {
      color: #50b5ff;
      cursor: pointer;
    }

    .add_new_post {
      border-radius: 0;
      height: 50px;
      font-size: 18px;
      margin-top: 20px;
    }

    .drag_container {
      width: 100%;
      padding-left: 20px;

      .drag_content {
        height: 160px;
        background: #f6f6f6;
      }

      // .el-upload-dragger {
      //   width: 500px;
      //   border: 0;
      //   height: auto;
      //   overflow: visible;
      // }

      .el-upload__text {
        color: #ccc;
        font-size: 14px;
        line-height: 16px;
        width: 146px;
        margin: 0 auto;
      }

      .el-loading-mask {
        background-color: rgba(255, 255, 255, 0.5);
      }

      .all_image {
        width: 100%;

        .four_img:nth-child(2) {
          margin-right: 30px !important;
        }

        .image_item:nth-child(3n) {
          margin-right: 0 !important;
        }

        .main_img,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }

      .common_item {
        position: relative;
        margin-bottom: 10px;

        .close_img {
          width: 20px;
          height: 20px;
          position: absolute;
          right: -5px;
          top: -5px;
        }
      }
    }
  }

  .location_box {
    display: flex;
    align-items: center;
    border: 1px solid #ffba68;
    color: #ffba68;
    padding: 0 6px;
    border-radius: 15px;
    margin-left: 20px;
    width: auto;
    line-height: 26px;

    i {
      margin-right: 3px;
      font-size: 18px;
    }

    .el-icon-close {
      font-size: 16px;
      margin-left: 5px;
      cursor: pointer;
    }
  }

  .modal_friend_list {
    display: flex;
    margin-left: 20px;
    margin-bottom: 10px;
    flex-wrap: wrap;

    .friend_box {
      display: flex;
      align-items: center;
      border: 1px solid #50b5ff;
      color: #50b5ff;
      padding: 0 6px;
      border-radius: 15px;
      width: auto;
      line-height: 24px;
      margin-right: 5px;
      margin-bottom: 5px;

      .el-icon-close {
        font-size: 16px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  ::v-deep .el-upload {
    margin: 0 auto;
    display: flex;
    // justify-content: center;
    justify-content: left;
  }

  ::v-deep .dropdown-toggle::after {
    display: none;
  }

  ::v-deep .btn-secondary {
    padding: 0;
    background-color: #fff !important;
    border-color: #fff !important;
  }

  .user-img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #f1f1f1;
    background: url("../../../assets/images/public/user.png");
    background-size: 100%;
  }

  .media_box {
    display: flex;
    flex-wrap: wrap;

    >.li_pic {
      width: 69px;
      height: 69px;
      margin-right: 14px;
      margin-top: 5px;
      margin-bottom: 5px;
      overflow: hidden;
      border-radius: 8px;
      position: relative;

      >img,
      >video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; //图片自适应的样式
      }

      .close_img {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 2px;
        top: 2px;
      }
    }
  }

  .iq-card-body:focus {
    outline: none;
  }

  .share_link {
    border: 1px solid #f1f1f1;
    background-color: #f8f8f8;
    display: flex;

    >.img_box {
      width: 80px;
      height: 80px;

      >img,
      video {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .share_content {
      width: 500px;
      padding: 2px 5px;

      >p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
    }
  }

  ::v-deep .textareaPost {
    height: 60px;

    .el-textarea__inner {
      height: 60px;
      border: none;
      resize: none;
    }

    .el-input__count {
      bottom: -20px;
      background: transparent;
    }
  }

  .post_remark_content {
    width: 100%;
    position: relative;
    margin-left: 20px;

    .detail_content {
      width: calc(100% - 15px);
      overflow: auto;
      height: 120px;
      margin-bottom: 20px;
      color: #333;
      word-break: break-word;
      /* Track */
      &::-webkit-scrollbar-track {
        background: #fff;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #fff;
      }

    }

    .showLave {
      position: absolute;
      bottom: -19px;
      right: 18px;
      font-size: 12px;
    }
  }

  .postTextarea {
    top: 0;
    position: absolute;
    width: 100%;
    height: 120px;
    border: none;
    resize: none;
    background-color: transparent;
    word-break: break-word;

    /* textarea::-webkit-scrollbar {
      display: none;
    } */
  }
</style>